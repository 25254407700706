import {
  IFormInputCheckbox,
  IFormInputRadio,
  IFormInputSelect,
  IFormInputText,
  ISection,
  ISectionCard,
  ISectionFeature,
  ISectionForm,
  ISectionFormWithoutTheme,
  ISectionFormWithoutThemeAndSectionData,
  ISectionMedia,
  ISectionWithoutTheme,
  ISectionWithoutThemeAndForms,
  ITheme,
  IIntro,
  IIntroWithoutTheme,
  IIntroWithoutThemeAndForms,
  IFormInputConsent,
} from '@/types'

const injectThemeInForm = (
  form: ISectionFormWithoutTheme,
  theme: ITheme,
): ISectionForm => {
  const formImputWithTheme: (
    | IFormInputText
    | IFormInputSelect
    | IFormInputRadio
    | IFormInputCheckbox
    | IFormInputConsent
  )[] = form.data.inputs.map((input) => {
    switch (input.type) {
      case 'input-text': {
        const inputWithTheme: IFormInputText = {
          ...input,
          data: {
            ...input.data,
            background: theme.inputColor,
            rounded: theme.rounded,
            textColor: theme.textInput,
          },
        }
        return inputWithTheme
      }
      case 'input-select': {
        const inputWithTheme: IFormInputSelect = {
          ...input,
          data: {
            ...input.data,
            background: theme.inputColor,
            rounded: theme.rounded,
            textColor: theme.textInput,
          },
        }
        return inputWithTheme
      }
      case 'input-radio': {
        const inputWithTheme: IFormInputRadio = {
          ...input,
          data: {
            ...input.data,
            background: theme.inputColor,
            checkedColor: theme.primaryColor,
            textColor: theme.textInput,
          },
        }
        return inputWithTheme
      }
      case 'input-checkbox': {
        const inputWithTheme: IFormInputCheckbox = {
          ...input,
          data: {
            ...input.data,
            rounded: theme.rounded,
            background: theme.inputColor,
            checkedColor: theme.primaryColor,
            textColor: theme.textInput,
          },
        }
        return inputWithTheme
      }
      case 'input-consent': {
        const inputWithTheme: IFormInputConsent = {
          ...input,
          data: {
            ...input.data,
            textColor: theme.textInput,
          },
        }

        return inputWithTheme
      }
      default: {
        const _exhaustiveCheck: never = input
        return _exhaustiveCheck
      }
    }
  })

  const formWIthTheme: ISectionForm = {
    ...form,
    data: {
      ...form.data,
      inputs: formImputWithTheme,
      buttonBackground: theme.primaryColor,
      rounded: theme.rounded,
      background: theme.backgroundColor,
    },
  }
  return formWIthTheme
}

/**
 * Injects the `form` object into all the placeholders in `section`.
 *
 * The theme injection will be performed in another function.
 */
export const injectFormInSection = (
  section: ISectionWithoutThemeAndForms,
  form: ISectionFormWithoutThemeAndSectionData
): ISectionWithoutTheme => {
  const newSection: ISectionWithoutTheme = { ...section, items: [] }

  newSection.items = section.items.map((item) => {
    if (item.type === 'form') {
      return {
        type: 'form',
        data: {
          ...item.data,
          ...form.data,
        }
      }
    }

    return item
  })

  return newSection
}

/**
 * Injects the `form` object into all the placeholders in `intro`.
 *
 * The theme injection will be performed in another function.
 */
export const injectFormInIntro = (
  intro: IIntroWithoutThemeAndForms,
  form: ISectionFormWithoutThemeAndSectionData
): IIntroWithoutTheme => {
  const newIntro: IIntroWithoutTheme = { ...intro, items: [] }

  newIntro.items = intro.items.map((item) => {
    if (item.type === 'form') {
      return {
        type: 'form',
        data: {
          ...item.data,
          ...form.data,
        }
      }
    }

    return item
  })

  return newIntro
}

/**
 * Injects the theme properties into a given section and child items.
 */
export const injectThemeInSection = (
  section: ISectionWithoutTheme,
  theme: ITheme
): ISection => {
  const newSection: ISection = {
    ...section,
    buttonBackground: theme.primaryColor,
    rounded: theme.rounded,
    background: section.background || theme.backgroundColor,
    items: [],
  }

  newSection.items = section.items.map((item) => {
    switch (item.type) {
      case 'card': {
        const itemWithTheme: ISectionCard = {
          ...item,
          data: {
            ...item.data,
            buttonBackground: theme.primaryColor,
            rounded: theme.rounded,
          },
        }
        return itemWithTheme
      }
      case 'feature': {
        const itemWithTheme: ISectionFeature = {
          ...item,
          data: {
            ...item.data,
            background: theme.secondaryColor,
            imageBackground: theme.primaryColor,
            rounded: theme.rounded,
          },
        }
        return itemWithTheme
      }
      case 'form': {
        return injectThemeInForm(item, theme)
      }
      case 'media': {
        const itemWithTheme: ISectionMedia = {
          ...item,
          rounded: theme.rounded,
          data: {
            ...item.data,
          },
        }
        return itemWithTheme
      }
      default: {
        const _exhaustiveCheck: never = item
        return _exhaustiveCheck
      }
    }
  })

  return newSection
}

/**
 * Injects the theme properties into a given section and child items.
 */
export const injectThemeInIntro = (
  intro: IIntroWithoutTheme,
  theme: ITheme
): IIntro => {
  const newIntro: IIntro = {
    ...intro,
    background: theme.backgroundColor,
    items: [],
  }

  newIntro.items = intro.items.map((item) => {
    switch (item.type) {
      case 'form': {
        return injectThemeInForm(item, theme)
      }
      case 'media': {
        const itemWithTheme: ISectionMedia = {
          ...item,
          rounded: theme.rounded,
          data: {
            ...item.data,
          },
        }
        return itemWithTheme
      }
      default: {
        const _exhaustiveCheck: never = item
        return _exhaustiveCheck
      }
    }
  })

  return newIntro
}
