import { IRestResult, IRestResultCreate, IRestResultEdit } from '@/types'
import axios from 'axios'

class CampaignRest {
  async get (id: number, token?: string|null): Promise<IRestResult> {
    return (await axios.get(`/api/campaigns/${id}`, {
      headers: token ? {
        Authorization: token
      } : {}
    })).data
  }

  async edit (token: string | null, id: number, data: IRestResultEdit): Promise<IRestResult> {
    return (await axios.patch(`/api/campaigns/${id}`, data, token ? {
      headers: {
        Authorization: token
      }
    } : {})).data
  }

  async create (token: string | null, data: IRestResultCreate): Promise<IRestResult> {
    return (await axios.post('/api/campaigns', data, token ? {
      headers: {
        Authorization: token
      }
    } : {})).data
  }
}

export default new CampaignRest()
