
import { TranslationHandler } from '../../mixins/translation-handler'
import { FormsState } from '../../store/modules/forms'
import { PageModulesState } from '../../store/modules/page-modules'
import { ThemeState } from '../../store/modules/theme'
import { IIntro, ISection, ISectionFormWithoutThemeAndSectionData } from '../../types'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { injectFormInIntro, injectFormInSection, injectThemeInIntro, injectThemeInSection } from '../../utils/injectors'

@Component({
  components: {},
})
export default class Home extends Mixins(TranslationHandler) {
  @Getter themeIsDark!: ThemeState['isDark']
  @Getter themeFont!: ThemeState['font']
  @Getter themePrimaryColor!: ThemeState['primaryColor']
  @Getter themeSecondaryColor!: ThemeState['secondaryColor']
  @Getter themeBackgroundColor!: ThemeState['backgroundColor']
  @Getter themeInputColor!: ThemeState['inputColor']
  @Getter themeFooterIconColor!: ThemeState['footerIconColor']
  @Getter themeText!: ThemeState['text']
  @Getter themeTextInput!: ThemeState['textInput']
  @Getter themeRounded!: ThemeState['rounded']
  @Getter themeButtonText!: ThemeState['buttonText']
  @Getter pageModulesHomeIntros!: PageModulesState['homeIntros']
  @Getter pageModulesHome!: PageModulesState['homePage']
  @Getter formNewsletter!: FormsState['newsletter']
  @Getter formCompetition!: FormsState['competition']
  @Getter formAllFormsAreSubmitted!: boolean
  @Getter formIsCompetitionSubmitted!: boolean

  isModalOpen = false

  @Watch('formIsCompetitionSubmitted')
  watchIsCompetitionSubmitted (val: boolean) {
    if (this.isModalActive && this.sectionModal && val) {
      this.isModalOpen = true
    }
  }

  @Watch('formAllFormsAreSubmitted')
  watchAllFormsAreSubmitted (val: boolean) {
    if (val === true) {
      // some campaigns should be redirected to a specific url like whatsapp campaigns
      if (this.formNewsletter?.data.afterSubmitUrl) {
        window.location.href = this.formNewsletter?.data.afterSubmitUrl

        return
      }

      // some campaigns should be redirected to a specific url like whatsapp campaigns
      if (this.formCompetition?.data.afterSubmitUrl) {
        window.location.href = this.formCompetition?.data.afterSubmitUrl

        return
      }

      this.$router.push({ name: 'Success' })
    }
  }

  get isModalActive (): boolean {
    return !!this.formNewsletter && !!this.formCompetition
  }

  get form (): ISectionFormWithoutThemeAndSectionData {
    if (this.formCompetition) {
      return this.formCompetition
    }

    // @ts-ignore (todo)
    return this.formNewsletter
  }

  get sectionModal () {
    if (this.formNewsletter === null) {
      return null
    }

    const section = injectFormInSection({
      messages: {
        en: {
          title: '',
          subtitle: '',
          button: '',
          buttonLink: '',
        }
      },
      backgroundImage: '',
      isFullWidth: true,
      isAlignmentCenter: true,
      cols: 1,
      items: [
        {
          type: 'form',
          data: {
            isAlignmentCenter: true,
            isPositionCenter: true,
          }
        }
      ]
    }, this.formNewsletter)

    return injectThemeInSection(section, {
      isDark: this.themeIsDark,
      text: this.themeText,
      font: this.themeFont,
      primaryColor: this.themePrimaryColor,
      secondaryColor: this.themeSecondaryColor,
      backgroundColor: this.themeBackgroundColor,
      inputColor: this.themeInputColor,
      textInput: this.themeTextInput,
      rounded: this.themeRounded,
      footerIconColor: this.themeFooterIconColor,
      buttonText: this.themeButtonText,
    })
  }

  get intros (): IIntro[] {
    if (this.form === null) {
      return []
    }

    // inject forms in pageModules object
    const homeIntrosWithForms = this.pageModulesHomeIntros.map((module) =>
      injectFormInIntro(module, this.form)
    )

    // inject theme in pageModules object
    const homeIntrosWIthTheme = homeIntrosWithForms.map((module) =>
      injectThemeInIntro(module, {
        isDark: this.themeIsDark,
        text: this.themeText,
        font: this.themeFont,
        primaryColor: this.themePrimaryColor,
        secondaryColor: this.themeSecondaryColor,
        backgroundColor: this.themeBackgroundColor,
        inputColor: this.themeInputColor,
        textInput: this.themeTextInput,
        footerIconColor: this.themeFooterIconColor,
        rounded: this.themeRounded,
        buttonText: this.themeButtonText,
      })
    )

    return homeIntrosWIthTheme
  }

  get sections (): ISection[] {
    if (this.form === null) {
      return []
    }

    // inject forms in pageModules object
    const homeWithForms = this.pageModulesHome.map((module) =>
      injectFormInSection(module, this.form)
    )

    // inject theme in pageModules object
    const homeWithTheme = homeWithForms.map((module) =>
      injectThemeInSection(module, {
        isDark: this.themeIsDark,
        text: this.themeText,
        font: this.themeFont,
        primaryColor: this.themePrimaryColor,
        secondaryColor: this.themeSecondaryColor,
        backgroundColor: this.themeBackgroundColor,
        inputColor: this.themeInputColor,
        footerIconColor: this.themeFooterIconColor,
        textInput: this.themeTextInput,
        rounded: this.themeRounded,
        buttonText: this.themeButtonText,
      })
    )

    return homeWithTheme
  }
}
