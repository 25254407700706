import Vuex, {
  MutationTree,
  GetterTree,
  ActionTree,
  Module,
  ActionContext,
} from 'vuex'
import Vue from 'vue'
import { RootState } from '..'
import users from '@/rest/users'
import { IUser } from '@/types'
import { transformObjectToCamelCase } from '@/utils/casingConversions'

Vue.use(Vuex)

export interface UsersState {
  current: IUser | null
}

export const usersState: UsersState = {
  current: null,
}

const usersMutations: MutationTree<UsersState> = {
  SET_USER_CURRENT (state: UsersState, current: IUser) {
    state.current = current
  },
}

const usersActions: ActionTree<UsersState, RootState> = {
  async LOAD_USER (ctx: ActionContext<UsersState, RootState>, data: { token: string }) {
    const user = await users.me(data.token)
    if (user) {
      ctx.commit('SET_USER_CURRENT', transformObjectToCamelCase(user))
    }
  },
}

const usersGetters: GetterTree<UsersState, RootState> = {
  userCurrent: (state: UsersState) => state.current,
}

export const usersModule: Module<UsersState, RootState> = {
  state: usersState,
  mutations: usersMutations,
  getters: usersGetters,
  actions: usersActions,
}
