import transform from 'lodash/transform'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'

/**
 * Generate snake case typings from camel case object
 */
 type CamelToSnakeCase<S extends string> =
 S extends `${infer T}${infer U}` ?
 `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}` :
 S
/**
* Recursively generate snake case typings from camel case object
*/
export type CamelToSnakeCaseNested<T> = T extends Record<string, unknown> ? {
 [K in keyof T as CamelToSnakeCase<K & string>]: CamelToSnakeCaseNested<T[K]>
} : T
/**
* Generate camel case typings from snake case object
*/
type SnakeToCamelCase<S extends string> =
S extends `${infer T}_${infer U}` ?
`${T}${Capitalize<SnakeToCamelCase<U>>}` :
S
/**
* Recursively generate camel case typings from snake case object
*/
export type SnakeToCamelCaseNested<T> = T extends Record<string, unknown> ? {
[K in keyof T as SnakeToCamelCase<K & string>]: SnakeToCamelCaseNested<T[K]>
} : T

/**
 * Recursively transforms an object from snake_case to camelCase keys.
 * Usefull for API to Vuex conversions.
 */
export const transformObjectToCamelCase = (obj: Record<string, any>) =>
  transform(obj, (acc: Record<string, any>, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key)
    acc[camelKey] = isObject(value) ? transformObjectToCamelCase(value) : value
  })

/**
 * Recursively transforms an object from camelCase to snake_case keys.
 * Usefull for Vuex to API conversions.
 */
export const transformObjectToSnakeCase = (obj: Record<string, any>) =>
  transform(obj, (acc: Record<string, any>, value, key, target) => {
    const camelKey = isArray(target) ? key : snakeCase(key)
    acc[camelKey] = isObject(value) ? transformObjectToSnakeCase(value) : value
  })
