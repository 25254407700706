import { FormsState } from '@/store/modules/forms'
import { PageModulesState } from '@/store/modules/page-modules'
import { ThemeState } from '@/store/modules/theme'
import { Vue, Component, Watch } from 'vue-property-decorator'

enum MessageType {
  VIEW = 'view',
  THEME = 'theme',
  PAGE_MODULE = 'page-module',
  FORMS = 'forms'
}

// this is to listen and send messages between admin and app
@Component
export class BridgeHandler extends Vue {
  public $bridgeMessageTheme (state: ThemeState) {
    this.$bridgeMessage(MessageType.THEME, { state })
  }

  public $bridgeMessagePageModules (state: PageModulesState) {
    this.$bridgeMessage(MessageType.PAGE_MODULE, { state })
  }

  public $bridgeMessageForms (state: FormsState) {
    this.$bridgeMessage(MessageType.FORMS, { state })
  }

  public $bridgeMessageView (view: string) {
    this.$bridgeMessage(MessageType.VIEW, { view })
  }

  private $bridgeMessage (type: MessageType, data: Record<string, unknown>) {
    window.postMessage({ admin: true, type, ...data }, window.origin)
  }

  public $bridgeListenMessages () {
    window.parent.addEventListener('message', this.$bridgeHandleMessage)
  }

  private $bridgeHandleMessage (event: MessageEvent): void {
    if (!event.data.admin) return

    switch (event.data.type) {
      case MessageType.VIEW: {
        this.$router.push(`/${event.data.view}`)

        break
      }
      case MessageType.THEME: {
        this.$store.commit('SET_THEME_IS_DARK', event.data.state.isDark)
        this.$store.commit('SET_THEME_BUTTON_TEXT_COLOR', event.data.state.buttonText)
        this.$store.commit('SET_THEME_PRIMARY_COLOR', event.data.state.primaryColor)
        this.$store.commit('SET_THEME_FONT', event.data.state.font)
        this.$store.commit('SET_THEME_SECONDARY_COLOR', event.data.state.secondaryColor)
        this.$store.commit('SET_THEME_BACKGROUND_COLOR', event.data.state.backgroundColor)
        this.$store.commit('SET_THEME_INPUT_COLOR', event.data.state.inputColor)
        this.$store.commit('SET_THEME_TEXT_INPUT_COLOR', event.data.state.textInput)
        this.$store.commit('SET_THEME_TEXT_COLOR', event.data.state.text)
        this.$store.commit('SET_THEME_FOOTER_ICON_COLOR', event.data.state.footerIconColor)
        this.$store.commit('SET_THEME_ROUNDED', event.data.state.rounded)
        break
      }
      case MessageType.PAGE_MODULE: {
        this.$store.commit('SET_HOME_INTROS', event.data.state.homeIntros)
        this.$store.commit('SET_HOME_PAGE_MODULES', event.data.state.homePage)
        this.$store.commit('SET_SUCCESS_INTROS', event.data.state.successIntros)
        this.$store.commit('SET_SUCCESS_PAGE_MODULES', event.data.state.successPage)
        this.$store.commit('SET_END_INTROS', event.data.state.endIntros)
        this.$store.commit('SET_END_PAGE_MODULES', event.data.state.endPage)
        break
      }
      case MessageType.FORMS: {
        this.$store.commit('SET_FORM_NEWSLETTER', event.data.state.newsletter)
        this.$store.commit('SET_FORM_COMPETITION', event.data.state.competition)
        break
      }
      default: {
        break
      }
    }
  }
}
