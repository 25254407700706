import { IThemeFont } from '@/types'
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export class ThemeHandler extends Vue {
  @Getter themeFont!: IThemeFont

  $themeInsertFont () {
    const style = document.createElement('link')
    style.rel = 'stylesheet'
    style.href = this.$themeFontLink

    document.head.appendChild(style)
  }

  get $themeFontFamily () {
    switch (this.themeFont) {
      case IThemeFont.PT_MONO: {
        return '"PT Mono", monospace'
      }
      case IThemeFont.POPPINS: {
        return '"Poppins", sans-serif'
      }
      case IThemeFont.MERRIWEATHER: {
        return '"Merriweather", serif'
      }
      default: {
        return ''
      }
    }
  }

  get $themeFontLink (): string {
    switch (this.themeFont) {
      case IThemeFont.POPPINS: {
        return 'https://fonts-googleapis-com.universal-music.de/css?family=Poppins:400,600,700&display=swap'
      }
      case IThemeFont.MERRIWEATHER: {
        return 'https://fonts-googleapis-com.universal-music.de/css2?family=Merriweather:wght@300;400;900&display=swap'
      }
      case IThemeFont.PT_MONO: {
        return 'https://fonts-googleapis-com.universal-music.de/css2?family=PT+Mono&display=swap'
      }
      default: {
        return ''
      }
    }
  }
}
