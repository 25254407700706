
import { TranslationHandler } from '@/mixins/translation-handler'
import { FormsState } from '@/store/modules/forms'
import { PageModulesState } from '@/store/modules/page-modules'
import { ThemeState } from '@/store/modules/theme'
import { IIntro, ISection, ISectionFormWithoutThemeAndSectionData } from '@/types'
import { Component, Mixins } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { injectFormInIntro, injectFormInSection, injectThemeInIntro, injectThemeInSection } from '@/utils/injectors'

@Component({
  components: {},
})
export default class Success extends Mixins(TranslationHandler) {
  @Getter themeIsDark!: ThemeState['isDark']
  @Getter themeFont!: ThemeState['font']
  @Getter themePrimaryColor!: ThemeState['primaryColor']
  @Getter themeSecondaryColor!: ThemeState['secondaryColor']
  @Getter themeBackgroundColor!: ThemeState['backgroundColor']
  @Getter themeInputColor!: ThemeState['inputColor']
  @Getter themeFooterIconColor!: ThemeState['footerIconColor']
  @Getter themeText!: ThemeState['text']
  @Getter themeTextInput!: ThemeState['textInput']
  @Getter themeButtonText!: ThemeState['buttonText']
  @Getter themeRounded!: ThemeState['rounded']
  @Getter pageModulesSuccess!: PageModulesState['successPage']
  @Getter pageModulesSuccessIntros!: PageModulesState['successIntros']
  @Getter formNewsletter!: FormsState['newsletter']
  @Getter formCompetition!: FormsState['competition']

  mounted () {
    window.scrollTo(0, 0)
    document.body.classList.remove('overflow-hidden')
  }

  get form (): ISectionFormWithoutThemeAndSectionData {
    if (this.formNewsletter) {
      return this.formNewsletter
    }

    // @ts-ignore (todo)
    return this.formCompetition
  }

  get intros (): IIntro[] {
    if (this.form === null) {
      return []
    }

    // inject forms in pageModules object
    const homeIntrosWithForms = this.pageModulesSuccessIntros.map((module) =>
      injectFormInIntro(module, this.form)
    )

    // inject theme in pageModules object
    const homeIntrosWIthTheme = homeIntrosWithForms.map((module) =>
      injectThemeInIntro(module, {
        isDark: this.themeIsDark,
        text: this.themeText,
        font: this.themeFont,
        primaryColor: this.themePrimaryColor,
        secondaryColor: this.themeSecondaryColor,
        backgroundColor: this.themeBackgroundColor,
        inputColor: this.themeInputColor,
        footerIconColor: this.themeFooterIconColor,
        textInput: this.themeTextInput,
        rounded: this.themeRounded,
        buttonText: this.themeButtonText,
      })
    )

    return homeIntrosWIthTheme
  }

  get sections (): ISection[] {
    if (this.form === null) {
      return []
    }

    // inject forms in pageModules object
    const pageModulesHomeWithForms = this.pageModulesSuccess.map((module) =>
      injectFormInSection(module, this.form)
    )

    // inject theme in pageModules object
    const pageModulesHomeWithTheme = pageModulesHomeWithForms.map((module) =>
      injectThemeInSection(module, {
        isDark: this.themeIsDark,
        text: this.themeText,
        font: this.themeFont,
        primaryColor: this.themePrimaryColor,
        secondaryColor: this.themeSecondaryColor,
        backgroundColor: this.themeBackgroundColor,
        inputColor: this.themeInputColor,
        textInput: this.themeTextInput,
        rounded: this.themeRounded,
        buttonText: this.themeButtonText,
        footerIconColor: this.themeFooterIconColor,
      })
    )

    return pageModulesHomeWithTheme
  }
}
