import Vuex, {
  MutationTree,
  GetterTree,
  ActionTree,
  Module,
} from 'vuex'
import Vue from 'vue'
import { IIntroWithoutThemeAndForms, ISectionWithoutThemeAndForms } from '@/types'
import { RootState } from '..'

Vue.use(Vuex)

export interface PageModulesState {
  homeIntros: IIntroWithoutThemeAndForms[];
  homePage: ISectionWithoutThemeAndForms[];
  successIntros: IIntroWithoutThemeAndForms[];
  successPage: ISectionWithoutThemeAndForms[];
  endIntros: IIntroWithoutThemeAndForms[];
  endPage: ISectionWithoutThemeAndForms[];
}

export const pageModulesState: PageModulesState = {
  homeIntros: [],
  homePage: [],
  successIntros: [],
  successPage: [],
  endIntros: [],
  endPage: [],
}

const pageModulesMutations: MutationTree<PageModulesState> = {
  SET_HOME_INTROS (
    state: PageModulesState,
    modules: IIntroWithoutThemeAndForms[]
  ) {
    state.homeIntros = modules
  },
  SET_HOME_PAGE_MODULES (
    state: PageModulesState,
    modules: ISectionWithoutThemeAndForms[]
  ) {
    state.homePage = modules
  },
  SET_SUCCESS_INTROS (
    state: PageModulesState,
    modules: IIntroWithoutThemeAndForms[]
  ) {
    state.successIntros = modules
  },
  SET_SUCCESS_PAGE_MODULES (
    state: PageModulesState,
    modules: ISectionWithoutThemeAndForms[]
  ) {
    state.successPage = modules
  },
  SET_END_INTROS (
    state: PageModulesState,
    modules: IIntroWithoutThemeAndForms[]
  ) {
    state.endIntros = modules
  },
  SET_END_PAGE_MODULES (
    state: PageModulesState,
    modules: ISectionWithoutThemeAndForms[]
  ) {
    state.endPage = modules
  },
}

const pageModulesActions: ActionTree<PageModulesState, RootState> = {
}

const pageModulesGetters: GetterTree<PageModulesState, RootState> = {
  pageModulesHomeIntros: (state: PageModulesState) => state.homeIntros,
  pageModulesHome: (state: PageModulesState) => state.homePage,
  pageModulesSuccessIntros: (state: PageModulesState) => state.successIntros,
  pageModulesSuccess: (state: PageModulesState) => state.successPage,
  pageModulesEndIntros: (state: PageModulesState) => state.endIntros,
  pageModulesEnd: (state: PageModulesState) => state.endPage,
}

export const pageModulesModule: Module<PageModulesState, RootState> = {
  state: pageModulesState,
  mutations: pageModulesMutations,
  getters: pageModulesGetters,
  actions: pageModulesActions,
}
