import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../app/views/Home.vue'
import Raffle from '../app/views/Raffle.vue'
import Success from '../app/views/Success.vue'
import End from '../app/views/End.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home',
    name: 'Home-linked',
    component: Home
  },
  {
    path: '/success',
    name: 'Success',
    component: Success
  },
  {
    path: '/end',
    name: 'End',
    component: End
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
