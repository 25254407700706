import { englishCountries, germanCountries } from '@/admin/utils/countries'
import { IRestForms, IRestPageModules, IRestResult, IRestResultCreate, IRestResultEdit, IRestTheme, ISectionFormWithoutThemeAndSectionData, IThemeFont } from '@/types'
import { CamelToSnakeCaseNested } from '@/utils/casingConversions'
import { sleep } from '@/utils/sleep'

const THEME_MOCK: IRestTheme = {
  is_dark: false,
  primary_color: '#8386BC',
  secondary_color: '#D398AB',
  background_color: '#EBCDD6',
  text_input: '#000',
  input_color: '#D498AB',
  button_text: '#fff',
  text: '#000',
  font: IThemeFont.POPPINS,
  footer_icon_color: '#8386BC',
  rounded: 'full',
} as const

export const PAGE_MODULES_MOCK: IRestPageModules = {
  end_intros: [
    {
      background_image: 'https://dev.backend.universalmusic.digital/media/space-2.jpg',
      items: [
        {
          type: 'media',
          data: {
            width: '',
            type: 'image',
            messages: {
              en: {
                url: 'https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_916,h_301/https://thebackyardband.de/wp-content/uploads/2021/10/Logo_black.png',
              }
            }
          },
        },
      ],
    },
  ],
  end_page: [
    {
      messages: {
        en: {
          title: 'It\'s over!',
          subtitle: '',
          button: '',
          button_link: '',
        },
        de: {
          title: 'It\'s over!',
          subtitle: '',
          button: '',
          button_link: '',
        }
      },
      is_full_width: true,
      is_alignment_center: true,
      cols: 1,
      background_image: '',
      items: [
        {
          type: 'media',
          data: {
            width: '',
            type: 'image',
            messages: {
              en: {
                url: 'https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
              }
            }
          },
        },
      ],
    },
  ],
  success_intros: [
    {
      background_image: 'https://dev.backend.universalmusic.digital/media/space-2.jpg',
      items: [
        {
          type: 'media',
          data: {
            width: '',
            type: 'image',
            messages: {
              en: {
                url: 'https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_916,h_301/https://thebackyardband.de/wp-content/uploads/2021/10/Logo_black.png',
              }
            }
          },
        },
      ],
    },
  ],
  success_page: [
    {
      messages: {
        en: {
          title: 'Thanks for signing up!',
          subtitle: 'You will receive an email',
          button: '',
          button_link: '',
        },
        de: {
          title: 'Thanks for signing up!',
          subtitle: 'You will receive an email',
          button: '',
          button_link: '',
        }
      },
      is_full_width: true,
      is_alignment_center: true,
      cols: 1,
      background_image: '',
      items: [
        {
          type: 'media',
          data: {
            width: '',
            type: 'image',
            messages: {
              en: {
                url: 'https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
              }
            }
          },
        },
      ],
    },
  ],
  home_intros: [
    {
      background_image: '',
      items: [
        {
          type: 'media',
          data: {
            width: '',
            type: 'image',
            messages: {
              en: {
                url: 'https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_916,h_301/https://thebackyardband.de/wp-content/uploads/2021/10/Logo_black.png',
              }
            }
          },
        },
      ],
    },
    {
      background_image: 'https://dev.backend.universalmusic.digital/media/space-2.jpg',
      items: [
        {
          type: 'media',
          data: {
            width: '',
            type: 'image',
            messages: {
              en: {
                url: 'https://images.pexels.com/photos/736355/pexels-photo-736355.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=1600',
              }
            }
          },
        },
        {
          type: 'form',
          data: {
            is_alignment_center: true,
            is_position_center: true,
          },
        },
      ],
    },
  ],
  home_page: [
    {
      messages: {
        en: {
          title: 'Schlager find ich toll 1',
          subtitle:
            'Hier sind drei Gründe warum dieser Newsletter absolut hammer cool ist:',
          button: 'Abonnieren',
          button_link: 'https://takeit.agency/',
        },
        de: {
          title: 'Schlager find ich toll 1',
          subtitle:
            'Hier sind drei Gründe warum dieser Newsletter absolut hammer cool ist:',
          button: 'Abonnieren',
          button_link: 'https://takeit.agency/',
        }
      },
      is_full_width: true,
      is_alignment_center: false,
      cols: 4,
      background_image: '',
      items: [
        {
          type: 'feature',
          data: {
            messages: {
              en: {
                title: 'Alle News auf einen Blick',
              },
              de: {
                title: 'Alle News auf einen Blick',
              },
            },
            image_url: 'https://i.imgur.com/2PWo9EH.png',
            is_square: true,
          },
        },
        {
          type: 'feature',
          data: {
            messages: {
              en: {
                title: 'Alle News auf einen Blick',
              },
              de: {
                title: 'Alle News auf einen Blick',
              },
            },
            image_url: 'https://i.imgur.com/2PWo9EH.png',
            is_square: true,
          },
        },
        {
          type: 'feature',
          data: {
            messages: {
              en: {
                title: 'Alle News auf einen Blick',
              },
              de: {
                title: 'Alle News auf einen Blick',
              },
            },
            image_url: 'https://i.imgur.com/2PWo9EH.png',
            is_square: true,
          },
        },
        {
          type: 'feature',
          data: {
            messages: {
              en: {
                title: 'Alle News auf einen Blick',
              },
              de: {
                title: 'Alle News auf einen Blick',
              },
            },
            image_url: 'https://i.imgur.com/2PWo9EH.png',
            is_square: true,
          },
        },
      ],
    },
    {
      messages: {
        en: {
          title: 'Schlager find ich toll 2',
          subtitle:
            'Hier sind drei Gründe warum dieser Newsletter absolut hammer cool ist:',
          button: 'Abonnieren',
          button_link: 'https://takeit.agency/',
        },
        de: {
          title: 'Schlager find ich toll 2',
          subtitle:
            'Hier sind drei Gründe warum dieser Newsletter absolut hammer cool ist:',
          button: 'Abonnieren',
          button_link: 'https://takeit.agency/',
        }
      },
      is_full_width: false,
      is_alignment_center: true,
      cols: 3,
      background_image: '',
      items: [
        {
          type: 'feature',
          data: {
            messages: {
              en: {
                title: 'Alle News auf einen Blick',
              },
              de: {
                title: 'Alle News auf einen Blick',
              },
            },
            image_url: 'https://i.imgur.com/Njs80kQ.png',
            is_square: false,
          },
        },
        {
          type: 'feature',
          data: {
            messages: {
              en: {
                title: 'Alle News auf einen Blick',
              },
              de: {
                title: 'Alle News auf einen Blick',
              },
            },
            image_url: 'https://i.imgur.com/Njs80kQ.png',
            is_square: false,
          },
        },
        {
          type: 'feature',
          data: {
            messages: {
              en: {
                title: 'Alle News auf einen Blick',
              },
              de: {
                title: 'Alle News auf einen Blick',
              },
            },
            image_url: 'https://i.imgur.com/Njs80kQ.png',
            is_square: false,
          },
        },
        {
          type: 'feature',
          data: {
            messages: {
              en: {
                title: 'Alle News auf einen Blick',
              },
              de: {
                title: 'Alle News auf einen Blick',
              },
            },
            image_url: 'https://i.imgur.com/Njs80kQ.png',
            is_square: false,
          },
        },
        {
          type: 'feature',
          data: {
            messages: {
              en: {
                title: 'Alle News auf einen Blick',
              },
              de: {
                title: 'Alle News auf einen Blick',
              },
            },
            image_url: 'https://i.imgur.com/Njs80kQ.png',
            is_square: false,
          },
        },
        {
          type: 'feature',
          data: {
            messages: {
              en: {
                title: 'Alle News auf einen Blick',
              },
              de: {
                title: 'Alle News auf einen Blick',
              },
            },
            image_url: 'https://i.imgur.com/Njs80kQ.png',
            is_square: false,
          },
        },
      ],
    },
    {
      messages: {
        en: {
          title: '',
          subtitle: '',
          button: '',
          button_link: '',
        },
        de: {
          title: '',
          subtitle: '',
          button: '',
          button_link: '',
        },
      },
      is_full_width: true,
      is_alignment_center: false,
      cols: 1,
      background_image: '',
      items: [
        {
          type: 'card',
          data: {
            messages: {
              en: {
                title: 'Alles auf einen Blick',
                subtitle:
                  'Egal ob exklusive Unboxing, unheilige Videopremieren oder musikalische Schätze des Grafen - als Teil des unheiligen Newsletters verpasst du künftig nichts mehr!',
                button: 'Abonnieren',
                button_link: 'https://takeit.agency/',
              },
              de: {
                title: 'Alles auf einen Blick',
                subtitle:
                  'Egal ob exklusive Unboxing, unheilige Videopremieren oder musikalische Schätze des Grafen - als Teil des unheiligen Newsletters verpasst du künftig nichts mehr!',
                button: 'Abonnieren',
                button_link: 'https://takeit.agency/',
              }
            },
            image_url:
              'https://images.pexels.com/photos/8041082/pexels-photo-8041082.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
            background: '',
            is_image_first_desktop: true,
          },
        },
        {
          type: 'card',
          data: {
            messages: {
              en: {
                title: 'Alles auf einen Blick',
                subtitle:
                  'Egal ob exklusive Unboxing, unheilige Videopremieren oder musikalische Schätze des Grafen - als Teil des unheiligen Newsletters verpasst du künftig nichts mehr!',
                button: 'Abonnieren',
                button_link: 'https://takeit.agency/',
              },
              de: {
                title: 'Alles auf einen Blick',
                subtitle:
                  'Egal ob exklusive Unboxing, unheilige Videopremieren oder musikalische Schätze des Grafen - als Teil des unheiligen Newsletters verpasst du künftig nichts mehr!',
                button: 'Abonnieren',
                button_link: 'https://takeit.agency/',
              }
            },
            image_url:
              'https://images.pexels.com/photos/8041082/pexels-photo-8041082.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
            background: '',
            is_image_first_desktop: false,
          },
        },
        {
          type: 'card',
          data: {
            messages: {
              en: {
                title: 'Alles auf einen Blick',
                subtitle:
                  'Egal ob exklusive Unboxing, unheilige Videopremieren oder musikalische Schätze des Grafen - als Teil des unheiligen Newsletters verpasst du künftig nichts mehr!',
                button: 'Abonnieren',
                button_link: 'https://takeit.agency/',
              },
              de: {
                title: 'Alles auf einen Blick',
                subtitle:
                  'Egal ob exklusive Unboxing, unheilige Videopremieren oder musikalische Schätze des Grafen - als Teil des unheiligen Newsletters verpasst du künftig nichts mehr!',
                button: 'Abonnieren',
                button_link: 'https://takeit.agency/',
              }
            },
            background: '',
            image_url:
              'https://images.pexels.com/photos/8041082/pexels-photo-8041082.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500',
            is_image_first_desktop: true,
          },
        },
      ],
    },
    {
      messages: {
        en: {
          title: '',
          subtitle: '',
          button: '',
          button_link: '',
        },
        de: {
          title: '',
          subtitle: '',
          button: '',
          button_link: '',
        },
      },
      is_full_width: true,
      is_alignment_center: false,
      cols: 1,
      background_image: '',
      items: [
        {
          type: 'media',
          data: {
            width: '',
            type: 'image',

            messages: {
              en: {
                url: 'https://images.pexels.com/photos/1105666/pexels-photo-1105666.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
              }
            }
          },
        },
      ],
    },
    {
      messages: {
        en: {
          title: '',
          subtitle: '',
          button: '',
          button_link: '',
        },
        de: {
          title: '',
          subtitle: '',
          button: '',
          button_link: '',
        },
      },
      is_full_width: true,
      is_alignment_center: false,
      cols: 2,
      background_image: '',
      items: [
        {
          type: 'media',
          data: {
            width: '',
            type: 'spotifyEmbed',

            messages: {
              en: {
                url: 'https://open.spotify.com/embed/album/1DFixLWuPkv3KT3TnV35m3',
              }
            }
          },
        },
        {
          type: 'media',
          data: {
            width: '',
            type: 'appleEmbed',

            messages: {
              en: {
                url: 'https://embed.music.apple.com/us/playlist/heard-in-apple-ads/pl.b28c3a5975b04436b42680595f6983ad',
              }
            }
          },
        },
      ],
    },
    {
      messages: {
        en: {
          title: 'Jetzt anmelden! 1',
          subtitle:
            'Melde dich jetzt an und bekomme wöchentlich die neusten Songs und Videos per Email auf dein Smartphone',
          button: '',
          button_link: '',
        },
        de: {
          title: 'Jetzt anmelden! 1',
          subtitle:
            'Melde dich jetzt an und bekomme wöchentlich die neusten Songs und Videos per Email auf dein Smartphone',
          button: '',
          button_link: '',
        }
      },
      is_full_width: true,
      is_alignment_center: true,
      cols: 1,
      background_image: '',
      items: [
        {
          type: 'form',
          data: {
            is_alignment_center: true,
            is_position_center: true,
          },
        },
      ],
    },
    {
      messages: {
        en: {
          title: 'Jetzt anmelden! 2',
          subtitle:
            'Melde dich jetzt an und bekomme wöchentlich die neusten Songs und Videos per Email auf dein Smartphone',
          button: '',
          button_link: '',
        },
        de: {
          title: 'Jetzt anmelden! 2',
          subtitle:
            'Melde dich jetzt an und bekomme wöchentlich die neusten Songs und Videos per Email auf dein Smartphone',
          button: '',
          button_link: '',
        }
      },
      is_full_width: true,
      is_alignment_center: false,
      cols: 2,
      background_image: '',
      items: [
        {
          type: 'media',
          data: {
            width: '',
            type: 'image',

            messages: {
              en: {
                url: 'https://images.pexels.com/photos/2272854/pexels-photo-2272854.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
              }
            }
          },
        },
        {
          type: 'form',
          data: {
            is_alignment_center: false,
            is_position_center: false,
          },
        },
      ],
    },
  ],
}

const newsletterForm: CamelToSnakeCaseNested<ISectionFormWithoutThemeAndSectionData> = {
  type: 'form',
  data: {
    form_type: 'newsletter',
    messages: {
      en: {
        title: 'Super Newsletter nur hier und heute!',
        spotify_button_text: 'Mit Spotify anmelden',
        spotify_under_button_text: 'Text Spotify',
        small_text:
          'Du kannst Deine Einwilligung jederzeit widerrufen. Mehr Informationen unter Sicherheit & Datenschutz.',
        subtitle: '',
        bottom_text: 'Bottom text',
        button_text: 'Jetzt Anmelden',
        end_text:
          'Erhalte per E-Mail immer die neuesten Informationen zu Releases, Gewinnspielen und Aktionen. Du kannst Deine Einwilligung jederzeit widerrufen. Mehr Informationen unter Sicherheit & Datenschutz.',
      },
      de: {
        title: 'Super Newsletter nur hier und heute!',
        spotify_button_text: 'Mit Spotify anmelden',
        spotify_under_button_text: 'Text Spotify',
        small_text:
          'Du kannst Deine Einwilligung jederzeit widerrufen. Mehr Informationen unter Sicherheit & Datenschutz.',
        subtitle: '',
        bottom_text: 'Bottom text',
        button_text: 'Jetzt Anmelden',
        end_text:
          'Erhalte per E-Mail immer die neuesten Informationen zu Releases, Gewinnspielen und Aktionen. Du kannst Deine Einwilligung jederzeit widerrufen. Mehr Informationen unter Sicherheit & Datenschutz.',
      }
    },
    is_required: true,
    background: '',
    inputs: [
      {
        type: 'input-text',
        data: {
          id: 'name',
          messages: {
            en: {
              error: 'This is required',
              label: 'Name*',
              placeholder: 'Type your name',
            },
            de: {
              error: 'This is required',
              label: 'Name*',
              placeholder: 'Type your name',
            }
          },
          required: true,
          type: 'text',
        },
      },
      {
        type: 'input-text',
        data: {
          id: 'email',
          messages: {
            en: {
              error: 'This is not a valid email',
              label: 'Email*',
              placeholder: 'Type your email',
            }
          },
          required: true,
          type: 'email',
        },
      },
      {
        type: 'input-select',
        data: {
          type: 'country',
          id: 'country',
          messages: {
            en: {
              error: 'This is required',
              label: 'Country*',
              placeholder: 'Select your country',
            }
          },
          required: true,
          items: [],
        },
      },
      {
        type: 'input-checkbox',
        data: {
          messages: {
            en: {
              overline: '',
              error: 'This is required',
              label:
                'Mit deiner Anmeldung bestätigst du, dass du unsere Unheilig Newsletter erhalten möchtest.',
            }
          },
          required: false,
        },
      },
      {
        type: 'input-radio',
        data: {
          messages: {
            en: {
              label: 'Radio label',
              error: 'This is required',
            }
          },
          required: false,
          items: [
            {
              messages: {
                en: {
                  label: 'Option 1'
                },
                de: {
                  label: 'Optione 1'
                },
              },
              id: 'option-1'
            },
            {
              messages: {
                en: {
                  label: 'Option 2'
                },
                de: {
                  label: 'Optione 2'
                },
              },
              id: 'option-2'
            }
          ],
        }
      },
    ],
  },
}

const competitionForm: CamelToSnakeCaseNested<ISectionFormWithoutThemeAndSectionData> = {
  type: 'form',
  data: {
    is_required: false,
    form_type: 'newsletter',
    messages: {
      en: {
        title: 'Super Newsletter nur hier und heute!',
        spotify_button_text: 'Mit Spotify anmelden',
        spotify_under_button_text: 'Text Spotify',
        secondary_button_text: 'Skip',
        small_text:
          'Du kannst Deine Einwilligung jederzeit widerrufen. Mehr Informationen unter Sicherheit & Datenschutz.',
        subtitle: '',
        bottom_text: 'Bottom text',
        button_text: 'Jetzt Anmelden',
        end_text:
          'Erhalte per E-Mail immer die neuesten Informationen zu Releases, Gewinnspielen und Aktionen. Du kannst Deine Einwilligung jederzeit widerrufen. Mehr Informationen unter Sicherheit & Datenschutz.',
      },
      de: {
        title: 'Super Newsletter nur hier und heute!',
        spotify_button_text: 'Mit Spotify anmelden',
        spotify_under_button_text: 'Text Spotify',
        secondary_button_text: 'Skip',
        small_text:
          'Du kannst Deine Einwilligung jederzeit widerrufen. Mehr Informationen unter Sicherheit & Datenschutz.',
        subtitle: '',
        bottom_text: 'Bottom text',
        button_text: 'Jetzt Anmelden',
        end_text:
          'Erhalte per E-Mail immer die neuesten Informationen zu Releases, Gewinnspielen und Aktionen. Du kannst Deine Einwilligung jederzeit widerrufen. Mehr Informationen unter Sicherheit & Datenschutz.',
      },
    },
    button_icon: 'whatsapp',
    after_submit_url: 'http://localhost:8080/test-url',
    background: '',
    inputs: [
      {
        type: 'input-text',
        data: {
          id: 'name',
          messages: {
            en: {
              error: 'This is required',
              label: 'Name*',
              placeholder: 'Type your name',
            },
            de: {
              error: 'This is required',
              label: 'Name*',
              placeholder: 'Type your name',
            }
          },
          required: true,
          type: 'text',
        },
      },
      {
        type: 'input-text',
        data: {
          id: 'email',
          messages: {
            en: {
              error: 'This is required',
              label: 'Email*',
              placeholder: 'Type your email',
            },
            de: {
              error: 'This is required',
              label: 'Email*',
              placeholder: 'Type your email',
            }
          },
          required: true,
          type: 'email',
        },
      },
      {
        type: 'input-text',
        data: {
          messages: {
            en: {
              error: 'This is required',
              label: 'Address',
              placeholder: 'Type your address',
            },
            de: {
              error: 'This is required',
              label: 'Address',
              placeholder: 'Type your address',
            }
          },
          required: false,
          type: 'text',
        },
      },
      {
        type: 'input-text',
        data: {
          id: 'phone',
          messages: {
            en: {
              error: 'This is required',
              label: 'Phone',
              placeholder: 'Type your phone number',
            },
            de: {
              error: 'This is required',
              label: 'Phone',
              placeholder: 'Type your phone number',
            }
          },
          required: false,
          preSelect: 'nl',
          type: 'telephone',
        },
      },
      {
        type: 'input-consent',
        data: {
          id: 'consent',
          messages: {
            en: {
              error: '',
              label: 'Consent Text',
              text: 'This is consent text',
            },
            de: {
              error: '',
              label: 'Consent Text',
              text: 'This is consent text',
            }
          },
          required: false,
        },
      },
      {
        type: 'input-select',
        data: {
          id: 'country',
          type: 'country',
          messages: {
            en: {
              error: 'This is required',
              label: 'Country',
              placeholder: 'Select your country',
            },
            de: {
              error: 'This is required',
              label: 'Country',
              placeholder: 'Select your country',
            }
          },
          required: false,
          items: [],
        },
      },
      {
        type: 'input-checkbox',
        data: {
          messages: {
            en: {
              overline: '',
              error: 'This is required',
              label:
                'Mit deiner Anmeldung bestätigst du, dass du unsere Unheilig Newsletter erhalten möchtest. *',
            },
            de: {
              overline: '',
              error: 'This is required',
              label:
                'Mit deiner Anmeldung bestätigst du, dass du unsere Unheilig Newsletter erhalten möchtest. *',
            }
          },
          required: true,
        },
      },
    ],
  },
}

export const FORMS_MOCK: IRestForms = {
  newsletter_form: newsletterForm,
  competition_form: competitionForm,
}

class CampaignRestMock {
  public async get (id: number, token?: string|null): Promise<IRestResult> {
    await sleep(1200)

    return new Promise((resolve) => resolve({
      id,
      theme: THEME_MOCK,
      forms: FORMS_MOCK,
      pages: PAGE_MODULES_MOCK,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      setup_done: true,
    }))
  }

  public async edit (token: string | null, id: number, data: IRestResultEdit): Promise<IRestResult> {
    await sleep(1200)

    console.log('data', data)
    return new Promise((resolve) => resolve({
      ...data,
      id,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    }))
  }
}

export default new CampaignRestMock()
