import { CamelToSnakeCaseNested } from './utils/casingConversions'

export interface ILink {
  text: string
  url: string
}

export interface ISocial {
  id: 'spotify' | 'youtube' | 'instagram' | 'twitter' | 'facebook' | 'appleMusic' | string
  url: string
}

export type ISectionCard = {
  type: 'card'
  data: {
    messages: {
      [lang: string]: {
        title: string
        subtitle: string
        button: string
        buttonLink: string
      }
    }
    imageUrl: string
    background: string // color
    buttonLink?: string // moved to messages
    buttonBackground: IRestTheme['primary_color']
    isImageFirstDesktop: boolean
    rounded: IRestTheme['rounded']
  }
}
export type ISectionCardWithoutTheme = Omit<ISectionCard, 'data'>
  & { data: Omit<ISectionCard['data'], 'buttonBackground' | 'rounded'> }

export type ISectionFeature = {
  type: 'feature'
  data: {
    messages: {
      [lang: string]: {
        title: string
      }
    }
    background: IRestTheme['secondary_color']
    imageUrl: string
    imageBackground: IRestTheme['primary_color']
    isSquare: boolean // (sets icon background and background)
    rounded: IRestTheme['rounded']
  }
}
export type ISectionFeatureWithoutTheme = Omit<ISectionFeature, 'data'>
  & { data: Omit<ISectionFeature['data'], 'background' | 'imageBackground' | 'rounded'> }

export type ISectionForm = {
  type: 'form'
  data: {
    formType: 'newsletter' | 'competition' | 'combined'
    messages: {
      [lang: string]: {
        title: string
        spotifyButtonText?: string
        spotifyUnderButtonText?: string
        smallText: string
        subtitle: string
        bottomText: string
        buttonText: string
        endText: string
        secondaryButtonText?: string
      }
    }
    background: string
    /**
     * default on left (only title/subtile)
     */
    isAlignmentCenter: boolean
    /**
     * position, default left
     */
    isPositionCenter: boolean
    inputs: (IFormInputText | IFormInputSelect | IFormInputRadio | IFormInputCheckbox | IFormInputConsentWithoutTheme)[]
    buttonBackground: IRestTheme['primary_color']
    rounded: IRestTheme['rounded']
    /**
     * if not required, form can be skipped
     */
    isRequired: boolean

    /**
     * icon shoown next to button text, e.g. whatsapp
     */
    buttonIcon?: string
    /**
     * url to open after submit
     * if not set, show success message
     */
    afterSubmitUrl?: string
  }
}
export type ISectionFormWithoutThemeInputs = (IFormInputTextWithoutTheme | IFormInputSelectWithoutTheme | IFormInputRadioWithoutTheme | IFormInputCheckboxWithoutTheme | IFormInputConsentWithoutTheme)[]
export type ISectionFormWithoutThemeAndSectionData = Omit<ISectionForm, 'data'>
  & { data: Omit<ISectionForm['data'], 'buttonBackground' | 'rounded' | 'inputs' | 'isAlignmentCenter' | 'isPositionCenter'> }
  & { data: { inputs: ISectionFormWithoutThemeInputs } }
/**
 * Empty form placeholder object, to be populated at render time.
 */
export type ISectionFormPlaceholder = Omit<ISectionForm, 'data'> & {
  data: {
    isAlignmentCenter: boolean
    isPositionCenter: boolean
  }
}

export type ISectionFormWithoutTheme = ISectionFormWithoutThemeAndSectionData & ISectionFormPlaceholder

export type ISectionMedia = {
  type: 'media'
  rounded: IRestTheme['rounded']
  data: {
    /**
     * depending on type show media
     */
    type: 'video' | 'videoEmbed' | 'spotifyEmbed' | 'appleEmbed' | 'image'
    url?: string // old way of adding a URL
    width: string
    messages: { // for each language a different url
      [lang: string]: {
        url: string
      }
    }
  }
}
export type ISectionMediaWithoutTheme = Omit<ISectionMedia, 'rounded'>

export type ISection = {
  messages: {
    [lang: string]: {
      title: string
      subtitle: string
      button: string // after subtitle
      buttonLink: string
    }
  }
  buttonLink?: string // moved to messages
  buttonBackground: IRestTheme['primary_color']
  rounded: IRestTheme['rounded']
  background?: IRestTheme['background_color']
  backgroundImage?: string
  isFullWidth: boolean
  isAlignmentCenter: boolean // defaul on left
  cols: 1 | 2 | 3 | 4; // amount of cols in a row
  items: (ISectionCard | ISectionFeature | ISectionForm | ISectionMedia)[]
}
export type ISectionWithoutTheme = Omit<ISection, 'buttonBackground' | 'rounded' | 'items'>
  & { items: (ISectionCardWithoutTheme | ISectionFeatureWithoutTheme | ISectionFormWithoutTheme | ISectionMediaWithoutTheme)[] }
export type ISectionWithoutThemeAndForms = Omit<ISection, 'buttonBackground' | 'rounded' | 'items'>
  & { items: (ISectionCardWithoutTheme | ISectionFeatureWithoutTheme | ISectionFormPlaceholder | ISectionMediaWithoutTheme)[] }

export type IIntro = {
  backgroundImage: string
  background: IRestTheme['background_color']
  items: (ISectionForm | ISectionMedia)[]
}
export type IIntroWithoutTheme = Omit<IIntro, 'background' | 'items'>
  & { items: (ISectionFormWithoutTheme | ISectionMediaWithoutTheme)[] }
export type IIntroWithoutThemeAndForms = Omit<IIntro, 'background' | 'items'>
  & { items: (ISectionFormPlaceholder | ISectionMediaWithoutTheme)[] }

export type IFormInputText = {
  type: 'input-text'
  data: {
    id?: string
    messages: {
      [lang: string]: {
        label: string
        placeholder: string
        error?: string
      }
    }
    required: boolean
    type: 'email' | 'text' | 'telephone'
    background: IRestTheme['input_color']
    rounded: IRestTheme['rounded']
    textColor: IRestTheme['text_input']
    preSelect?: string // for tel
  }
}
export type IFormInputTextWithoutTheme = Omit<IFormInputText, 'data'> &
{ data: Omit<IFormInputText['data'], 'background' | 'rounded' | 'textColor'> }

export type IFormInputSelect = {
  type: 'input-select'
  data: {
    type: 'free' | 'country'
    id?: string
    messages: {
      [lang: string]: {
        label: string
        placeholder: string
        error?: string
      }
    }
    required: boolean
    background: IRestTheme['input_color']
    rounded: IRestTheme['rounded']
    textColor: IRestTheme['text_input']
    items: {
      messages: {
        [lang: string]: {
          label: string
        }
      }
      id: string
    }[]
  }
}
export type IFormInputSelectWithoutTheme = Omit<IFormInputSelect, 'data'> &
{ data: Omit<IFormInputSelect['data'], 'background' | 'rounded' | 'textColor'> }

export type IFormInputRadio = {
  type: 'input-radio'
  data: {
    id?: string
    messages: {
      [lang: string]: {
        label: string
        error?: string
      }
    }
    required: boolean
    background: IRestTheme['input_color']
    checkedColor: IRestTheme['primary_color']
    textColor: IRestTheme['text_input']
    items: {
      id: string
      messages: {
        [lang: string]: {
          label: string
        }
      }
    }[]
  }
}
export type IFormInputRadioWithoutTheme = Omit<IFormInputRadio, 'data'> &
{ data: Omit<IFormInputRadio['data'], 'background' | 'checkedColor' | 'textColor'> }

export type IFormInputCheckbox = {
  type: 'input-checkbox'
  data: {
    id?: string
    messages: {
      [lang: string]: {
        label: string
        overline: string
        error?: string
      }
    }
    required: boolean
    rounded: IRestTheme['rounded']
    background: IRestTheme['input_color']
    checkedColor: IRestTheme['primary_color']
    textColor: IRestTheme['text_input']
  }
}
export type IFormInputCheckboxWithoutTheme = Omit<IFormInputCheckbox, 'data'> &
{ data: Omit<IFormInputCheckbox['data'], 'rounded' | 'background' | 'checkedColor' | 'textColor'> }

export type IFormInputConsent = {
  type: 'input-consent'
  data: {
    id?: string
    messages: {
      [lang: string]: {
        error?: string
        label: string
        text: string
      }
    }
    required: boolean
    textColor: IRestTheme['text_input']
  }
}
export type IFormInputConsentWithoutTheme = Omit<IFormInputConsent, 'data'> &
{ data: Omit<IFormInputConsent['data'], 'textColor'> }

export interface IFooter {
  background: string // color
  textColor: string
  iconColor: string
  iconBackground: string
  isSquareSocial: boolean // sets all square
  links: ILink[]
  socialLinks: ISocial[]
}

export enum IThemeFont {
  MERRIWEATHER = 'Merriweather',
  POPPINS = 'Poppins',
  PT_MONO = 'PT Mono',
}

export type ITheme = {
  isDark: boolean
  primaryColor: string
  secondaryColor: string
  backgroundColor: string
  textInput: string
  inputColor: string
  text: string
  buttonText: string
  font: IThemeFont
  rounded: 'none' | 'full' | 'medium'
  footerIconColor: string
}

export type IRestTheme = CamelToSnakeCaseNested<ITheme>

export type IRestPageModulesIntro = (CamelToSnakeCaseNested<Omit<IIntroWithoutThemeAndForms, 'items'>>
  & {
    items: (
      CamelToSnakeCaseNested<ISectionFormPlaceholder> |
      CamelToSnakeCaseNested<ISectionMediaWithoutTheme>)[]
  }
)[]

export type IRestPageModulesPage = (CamelToSnakeCaseNested<Omit<ISectionWithoutThemeAndForms, 'items'>>
  & {
    items: (
      CamelToSnakeCaseNested<ISectionCardWithoutTheme> |
      CamelToSnakeCaseNested<ISectionFeatureWithoutTheme> |
      CamelToSnakeCaseNested<ISectionFormPlaceholder> |
      CamelToSnakeCaseNested<ISectionMediaWithoutTheme>)[]
  }
)[]

/**
 * This endpoint returns page-modules information.
 *
 * Forms are returned as placeholders, as they are fetched from another endpoint and injected later.
 */
export type IRestPageModules = {
  home_intros: IRestPageModulesIntro
  home_page: IRestPageModulesPage
  success_intros: IRestPageModulesIntro
  success_page: IRestPageModulesPage
  end_intros: IRestPageModulesIntro
  end_page: IRestPageModulesPage
}

export type IRestForms = {
  newsletter_form: CamelToSnakeCaseNested<ISectionFormWithoutThemeAndSectionData> | null
  competition_form: CamelToSnakeCaseNested<ISectionFormWithoutThemeAndSectionData> | null
}

export type IRestResult = {
  id: number
  theme: IRestTheme
  forms: IRestForms
  pages: IRestPageModules
  updated_at: string
  created_at: string
  setup_done: boolean
}

export type IRestResultEdit = Omit<IRestResult, 'id' | 'created_at' | 'updated_at'>
export type IRestResultCreate = Omit<IRestResult, 'id' | 'created_at' | 'updated_at'>

export type IUser = {
  campaignId: number
  country: null | string
  displayName: string
  email: null | string
  id: number
  profilePicture: string
  service: 'spotify' | 'apple' | ''
  serviceId: string
}

export type IRestUser = CamelToSnakeCaseNested<IUser>
