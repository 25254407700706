import { IRestUser } from '@/types'
import { sleep } from '@/utils/sleep'

class UsersMock {
  public async me (token: string): Promise<IRestUser | null> {
    await sleep(1200)

    return {
      id: 1,
      display_name: 'John Doe',
      email: null,
      service: 'spotify',
      service_id: '123',
      profile_picture: '',
      country: null,
      campaign_id: 1,
    }
  }
}

export default new UsersMock()
