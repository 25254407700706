import Vuex, { StoreOptions, MutationTree, GetterTree, ActionTree, ActionContext } from 'vuex'
import Vue from 'vue'
import { themeModule } from './modules/theme'
import { pageModulesModule } from './modules/page-modules'
import { formsModule } from './modules/forms'
import { usersModule } from './modules/user'
import campaign from '@/rest/campaign'
import { transformObjectToCamelCase, transformObjectToSnakeCase } from '@/utils/casingConversions'
import { IRestForms, IRestPageModules } from '@/types'
import cms from '@/cms'

Vue.use(Vuex)

export interface RootState {
  campaignId: number
  campaignCreatedAt: string
  campaignUpdatedAt: string
  campaignSetupDone: boolean
}

export const rootState: RootState = {
  campaignId: -1,
  campaignCreatedAt: '',
  campaignUpdatedAt: '',
  campaignSetupDone: false,
}

export const rootMutations: MutationTree<RootState> = {

  SET_CAMPAIGN_ID (state: RootState, campaignId) {
    state.campaignId = campaignId
  },
  SET_CAMPAIGN_CREATED_AT (state: RootState, campaignCreatedAt) {
    state.campaignCreatedAt = campaignCreatedAt
  },
  SET_CAMPAIGN_UPDATED_AT (state: RootState, campaignUpdatedAt) {
    state.campaignUpdatedAt = campaignUpdatedAt
  },
  SET_CAMPAIGN_SETUP_DONE (state: RootState, setupDone: boolean) {
    state.campaignSetupDone = setupDone
  }
}

export const rootGetters: GetterTree<RootState, any> = {
  campaignId: (state: RootState) => state.campaignId,
  campaignCreatedAt: (state: RootState) => state.campaignCreatedAt,
  campaignUpdatedAt: (state: RootState) => state.campaignUpdatedAt,
  campaignIsNew: (state: RootState) => !state.campaignSetupDone,
  campaignIsEnded: () => cms.campaign.hasEnded(),
}

export const rootActions: ActionTree<RootState, any> = {
  async LOAD_CAMPAIGN (ctx: ActionContext<RootState, any>) {
    const res = await campaign.get(ctx.state.campaignId, cms.accessToken)

    ctx.commit('SET_CAMPAIGN_CREATED_AT', res.created_at)
    ctx.commit('SET_CAMPAIGN_UPDATED_AT', res.updated_at)
    ctx.commit('SET_CAMPAIGN_SETUP_DONE', res.setup_done)

    ctx.commit('SET_THEME_IS_DARK', res.theme.is_dark)
    ctx.commit('SET_THEME_PRIMARY_COLOR', res.theme.primary_color)
    ctx.commit('SET_THEME_SECONDARY_COLOR', res.theme.secondary_color)
    ctx.commit('SET_THEME_BACKGROUND_COLOR', res.theme.background_color)
    ctx.commit('SET_THEME_INPUT_COLOR', res.theme.input_color)
    ctx.commit('SET_THEME_TEXT_INPUT_COLOR', res.theme.text_input)
    ctx.commit('SET_THEME_TEXT_COLOR', res.theme.text)
    ctx.commit('SET_THEME_FONT', res.theme.font)
    ctx.commit('SET_THEME_ROUNDED', res.theme.rounded)
    ctx.commit('SET_THEME_FOOTER_ICON_COLOR', res.theme.footer_icon_color || res.theme.primary_color)

    ctx.commit('SET_HOME_INTROS', transformObjectToCamelCase(res.pages.home_intros))
    ctx.commit('SET_HOME_PAGE_MODULES', transformObjectToCamelCase(res.pages.home_page))
    ctx.commit('SET_SUCCESS_INTROS', transformObjectToCamelCase(res.pages.success_intros))
    ctx.commit('SET_SUCCESS_PAGE_MODULES', transformObjectToCamelCase(res.pages.success_page))
    ctx.commit('SET_END_INTROS', transformObjectToCamelCase(res.pages.end_intros))
    ctx.commit('SET_END_PAGE_MODULES', transformObjectToCamelCase(res.pages.end_page))

    if (res.theme.button_text) {
      ctx.commit('SET_THEME_BUTTON_TEXT_COLOR', res.theme.button_text)
    }

    if (res.forms.newsletter_form) {
      ctx.commit('SET_FORM_NEWSLETTER', transformObjectToCamelCase(res.forms.newsletter_form))
    }
    if (res.forms.competition_form) {
      ctx.commit('SET_FORM_COMPETITION', transformObjectToCamelCase(res.forms.competition_form))
    }
  },
  async EDIT_CAMPAIGN (ctx: ActionContext<RootState, any>) {
    const result = await campaign.edit(cms.accessToken, ctx.state.campaignId, {
      theme: {
        is_dark: ctx.getters.themeIsDark,
        primary_color: ctx.getters.themePrimaryColor,
        secondary_color: ctx.getters.themeSecondaryColor,
        background_color: ctx.getters.themeBackgroundColor,
        input_color: ctx.getters.themeInputColor,
        footer_icon_color: ctx.getters.themeFooterIconColor,
        text_input: ctx.getters.themeTextInput,
        text: ctx.getters.themeText,
        font: ctx.getters.themeFont,
        rounded: ctx.getters.themeRounded,
        button_text: ctx.getters.themeButtonText,
      },
      pages: {
        home_intros: transformObjectToSnakeCase(ctx.getters.pageModulesHomeIntros) as IRestPageModules['home_intros'],
        home_page: transformObjectToSnakeCase(ctx.getters.pageModulesHome) as IRestPageModules['home_page'],
        success_intros: transformObjectToSnakeCase(ctx.getters.pageModulesSuccessIntros) as IRestPageModules['success_intros'],
        success_page: transformObjectToSnakeCase(ctx.getters.pageModulesSuccess) as IRestPageModules['success_page'],
        end_intros: transformObjectToSnakeCase(ctx.getters.pageModulesEndIntros) as IRestPageModules['end_intros'],
        end_page: transformObjectToSnakeCase(ctx.getters.pageModulesEnd) as IRestPageModules['end_page'],
      },
      forms: {
        newsletter_form: ctx.getters.formNewsletter !== null
          ? transformObjectToSnakeCase(ctx.getters.formNewsletter) as IRestForms['newsletter_form']
          : null,
        competition_form: ctx.getters.formCompetition !== null
          ? transformObjectToSnakeCase(ctx.getters.formCompetition) as IRestForms['competition_form']
          : null,
      },
      setup_done: true,
    })
    ctx.commit('SET_CAMPAIGN_UPDATED_AT', result.created_at)
    ctx.commit('SET_CAMPAIGN_SETUP_DONE', result.setup_done)
    cms.campaignSaved(result.id)
  }
}

export const storeOptions: StoreOptions<RootState> = {
  state: rootState,
  mutations: rootMutations,
  getters: rootGetters,
  actions: rootActions,
  modules: {
    theme: themeModule,
    pageModules: pageModulesModule,
    forms: formsModule,
    users: usersModule
  },
}

export default new Vuex.Store(storeOptions)
