import cms from '@/cms'
import { Component, Vue } from 'vue-property-decorator'

@Component
export class TranslationHandler extends Vue {
  $t (id: string): string {
    const t = cms.i18n.t(id)

    return t === id ? '' : t
  }

  get $tLocales (): string[] {
    const locales: string[] = cms.i18n.translations
      // @ts-ignore
      ? cms.i18n.translations.map((t) => t.locale.code).filter((v) => v)
      : []

    return locales.sort()
  }

  get $tLocaleCurrent (): string {
    return cms.i18n.currentLocale?.code || this.$tLocales[0]
  }

  $setLocale (locale: string) {
    cms.i18n.userPreferredLocale = locale
  }

  $tx (messages: any, key: string): string {
    return cms.i18n.tx(messages, key)
  }

  get $userCountryCode (): string | null {
    return cms.i18n.userCountryCode
  }
}
